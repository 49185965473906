import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import * as React from "react";
import { ReactNode } from "react";

const TruncatedList = ({
  domains,
  maxRows,
  icon,
  undeterminedSize = false
}: {
  domains: string[];
  maxRows: number;
  icon: ReactNode;
  undeterminedSize?: boolean;
}) => {
  return (
    <Box>
      <List>
        {domains.slice(0, maxRows).map((domain) => (
          <ListItem key={`li-${domain}`} disablePadding>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText primary={domain} />
          </ListItem>
        ))}
      </List>
      {domains.length > maxRows ? (
        <Box>
          <Typography variant="subtitle2">
            {
              undeterminedSize ? (
                "+ several additional domains"
              ) : (
                `+${domains.slice(maxRows).length} additional domains`
              )
            }

          </Typography>
        </Box>
      ) : null}
    </Box>
  );
};

export default TruncatedList;
