import axios from "axios";

export const updateScreener = async (screenerId: string, data: object) => {
  return axios({
    method: "PUT",
    url: `/api/v1/screeners/${screenerId}`,
    withCredentials: true,
    data: data,
  });
};


export const createMetricUserUpgrade = () => {
  return axios({
    method: "POST",
    url: "/api/v1/metrics/user/upgrade",
    withCredentials: true,
  });
};

export const retryIteration = async (searchId: string, iterationId: string): Promise<void> => {
  return axios({
    method: "POST",
    url: `/api/v1/searches/${searchId}/iterations/${iterationId}/retry`,
    withCredentials: true,
  });
};