import {
  Table as MTable,
  TableCell as MTableCell,
  TableHead as MTableHead,
  TableContainer,
  TableProps,
} from "@mui/material";
import { CSSProperties, ReactNode } from "react";

import styles from "./index.module.scss";

export const ifNullThenDefault = (v: any, def: string, decimals = 2) => {
  if (typeof v === "number") {
    v = v.toFixed(decimals);
  }
  return v === null || v === undefined ? def : v;
};

export const formatNumber = (v: any, decimal: number) => {
  if (typeof v === "number") {
    return v.toFixed(decimal);
  } else {
    return v;
  }
};

export const TableHead = ({
  children,
  sx,
}: {
  children: ReactNode;
  sx?: CSSProperties;
}) => {
  return (
    <MTableHead
      sx={{
        whiteSpace: "nowrap",
        ...sx,
      }}
      className={styles.head}
    >
      {children}
    </MTableHead>
  );
};

export const TableCell = ({
  padding,
  children,
  sx,
  className,
  style,
}: {
  padding?: "normal" | "checkbox" | "none";
  children?: ReactNode;
  sx?: CSSProperties;
  style?: CSSProperties;
  className?: string;
}) => {
  return (
    <MTableCell
      padding={padding}
      style={style}
      className={className}
      sx={{
        whiteSpace: "nowrap",
        ...sx,
      }}
    >
      {children}
    </MTableCell>
  );
};

export const BaseTable = ({
  children,
  size = "small",
  stickyHeader = true,
  noBorder = false,
  ...props
}: TableProps & {
  children: ReactNode;
  noBorder?: boolean;
}) => {
  return (
    <TableContainer
      sx={{
        overflowY: "scroll",
        flexGrow: 1,
        border: noBorder ? "none" : "1px solid #e0e0e0",
      }}
    >
      <MTable
        stickyHeader={stickyHeader}
        size={size}
        sx={{
          height: "max-content",
          "& .MuiTableCell-root": {
            padding: "8px 20px",
            fontSize: "0.875rem",
          },
          "& .MuiTableBody-root .MuiTableRow-root:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.04)",
          },
          "& .MuiTableBody-root .MuiTableRow-root td": {
            borderBottom: "1px solid #e0e0e0",
          },
          ...props.sx,
        }}
        {...props}
      >
        {children}
      </MTable>
    </TableContainer>
  );
};

export const headerCellSx = {
  backgroundColor: "#f8f9fa !important",
  fontWeight: 600,
  padding: "12px 16px",
  borderBottom: "2px solid #e0e0e0",
} as const;
