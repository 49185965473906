import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import classNames from "classnames";
import React, { useContext, useEffect } from "react";
import { useImmer } from "use-immer";

import { useMediaQueryContext } from "../../providers/MediaQueryProvider";
import { PageHeaderContext } from "../../providers/PageHeaderProvider";
import styles from "../index.module.scss";





const AppBarHeader = () => {
  const pageHeader = useContext(PageHeaderContext);
  const { isLowRes, isMediumRes } = useMediaQueryContext();
  
  const [state, setState] = useImmer<{
      scrollPastHeader: boolean;
    }>({
      scrollPastHeader: false,
    });
  
  useEffect(() => {
    const onScroll = () => {
      setState((state) => {
        state.scrollPastHeader = window.scrollY > 5 * 16;
      });
    };
      // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, [setState]);
  
  const backButton = pageHeader.onBack ? (
    <IconButton
      onClick={(e) => {
        e.preventDefault();
        pageHeader.onBack?.();
      }}
    >
      <ChevronLeftIcon />
    </IconButton>
  ) : null;
  
  const subtitleHeader = pageHeader.subtitle ? (
    <div
      className={classNames(styles.header, styles.expanded)}
    >
      <Typography
        variant="h5"
        style={{
          margin: "1.2rem 0",
          fontWeight: 525,
        }}
      >
        {pageHeader.subtitle}
      </Typography>
    </div>
  ) : null;
  
  return pageHeader.loaded ? (
    <>
      <Paper
        className={classNames(styles.header, styles.expanded, {
          [styles.sticky]: pageHeader.sticky,
        })}
        elevation={state.scrollPastHeader && pageHeader.sticky ? 1 : 0}
        sx={{
          "--offset": `${5 + (pageHeader.isSet ? 5 : 0)}rem`,
        }}
      >
        <div
          className={styles.container}
          style={{
            paddingTop: "2rem",
          }}
        >
          <div className={styles.title}>
            {backButton}
            <Typography
              variant="h4"
              style={{
                margin: "0.75rem 0",
                fontWeight: "bold",
                fontSize: isMediumRes
                  ? "calc(2.125rem * 0.8)"
                  : isLowRes
                    ? "1.688"
                    : "2.125rem",
              }}
            >
              {pageHeader.title}
            </Typography>
            {pageHeader.help}
          </div>
          <div>{pageHeader.children}</div>
        </div>
      </Paper>
      {subtitleHeader}
    </>
  ) : null;
};

export default AppBarHeader;