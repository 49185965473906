import React, { ReactNode, createContext } from "react";
import { useImmer } from "use-immer";

export type PageHeaderContextValue = {
  loaded: boolean;
  isSet: boolean;
  setPageHeader: (
    title: string,
    subtitle?: string,
    onBack?: () => void,
    help?: ReactNode,
    children?: ReactNode,
    sticky?: boolean
  ) => void;
  title: string;
  subtitle?: string;
  onBack?: () => void;
  help?: ReactNode;
  children?: ReactNode;
  sticky?: boolean;
};

export const PageHeaderContext = createContext<PageHeaderContextValue>({
  loaded: false,
  isSet: false,
  setPageHeader: () => undefined,
  title: "",
  subtitle: "",
  onBack: undefined,
  help: null,
  children: null,
  sticky: false,
});

const PageHeaderProvider = ({ children }: { children: ReactNode }) => {
  const [state, setState] = useImmer<{
    loaded: boolean;
    isSet: boolean;
    title: string;
    subtitle?: string;
    onBack?: () => void;
    help?: ReactNode;
    children?: ReactNode;
    sticky?: boolean;
      }>({
        loaded: false,
        isSet: false,
        title: "",
        subtitle: "",
        onBack: undefined,
        help: null,
        children: null,
        sticky: false,
      });

  const context = {
    loaded: true,
    isSet: false,
    setPageHeader: (
      title: string,
      subtitle?: string,
      onBack?: () => void,
      help?: ReactNode,
      children?: ReactNode,
      sticky?: boolean
    ) => {
      setState((state) => {
        state.isSet = true;
        state.title = title;
        state.subtitle = subtitle;
        state.onBack = onBack;
        state.help = help;
        state.children = children;
        state.sticky = sticky;
      });
    },
    title: state.title,
    subtitle: state.subtitle,
    onBack: state.onBack,
    help: state.help,
    children: state.children,
    sticky: state.sticky,
  };

  return (
    <PageHeaderContext.Provider value={context}>
      {children}
    </PageHeaderContext.Provider>
  );
};

export default PageHeaderProvider;
