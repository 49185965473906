import { Link, Stack, Typography } from "@mui/material";

import PageHeader from "../../components/PageHeader";

const ErrorPage = () => {
  return (
    <>
      <PageHeader title="An unexpected error has occurred."/>
      <Stack
        sx={{
          gap: "0.5rem",
        }}
      >
        <Typography
          fontSize="1.25rem !important"
        >
          We are aware of the issue and will be working on a fix shortly.
        </Typography>
        <Typography
          fontSize="1.25rem !important"
        >
          If the issue persists, please contact your account manager or <Link fontSize="1.25rem !important" href="mailto:support@fintent.ai">support@fintent.ai</Link>
        </Typography>
      </Stack>
    </>
  );
};

export default ErrorPage;