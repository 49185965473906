import { Snackbar } from "@mui/material";
import MuiAlert, { AlertColor } from "@mui/material/Alert";
import * as React from "react";
import { CSSProperties, ReactNode } from "react";

const Toast = ({
  message,
  severity,
  open,
  onClose,
  style,
}: {
  message: string | ReactNode;
  severity: AlertColor;
  open: boolean;
  onClose: () => void;
  style?: CSSProperties;
}) => {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      open={open}
      onClose={onClose}
      autoHideDuration={6000}
      style={style}
    >
      <MuiAlert
        severity={severity}
        style={{
          whiteSpace: "pre",
        }}
      >
        {message}
      </MuiAlert>
    </Snackbar>
  );
};

export default Toast;
