import { ArrowRight } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import * as React from "react";
import { createContext, useContext, useEffect } from "react";
import { useImmer } from "use-immer";

import TruncatedList from "../../pages/watchlists/components/TruncatedList";
import { cleanDomains } from "../../pages/watchlists/components/utils";
import { ToastContext } from "../../providers/ToastProvider";
import { NewScreenerOption, Option, Screener } from "../../types/search";
import { camelize } from "../../utils";
import ModalComponents from "../Modal";

const Overview = ({
  screeners,
  selectedScreener,
  domainNames,
  watchlistId,
  searchId,
  searchIterationId,
  setSelectedScreener,
  newScreenerName,
  setNewScreenerName,
  phase,
  setNextPhase,
  onClose,
  refresh,
}: {
  screeners: Screener[];
  selectedScreener: Option;
  domainNames: string[];
  watchlistId: string,
  searchId: string,
  searchIterationId: string,
  setSelectedScreener: (list: Option) => void;
  newScreenerName: string;
  setNewScreenerName: (name: string) => void;
  phase: number;
  setNextPhase: (phase: number, redirectScreenerId: string) => void;
  onClose: () => void;
  refresh: () => void;
}) => {
  const { setError } = useContext(ToastContext);

  const [state, setState] = useImmer<{
    locked: boolean;
  }>({
    locked: false,
  });

  const onSubmit = (
    screenerId: string,
    newScreenerName: string,
    domainNames: string[],
    watchlistId: string,
    searchId: string,
    searchIterationId: string,
    phase: number,
    setNextPhase: (phase: number, redirectListId: string) => void
  ): Promise<void> => {
    setState((state) => {
      state.locked = true;
    });
    let body = undefined;
    if (searchId && searchIterationId) {
      body = {
        search_id: searchId,
        search_iteration_id: searchIterationId,
      };
    } else if (watchlistId) {
      body = {
        watchlist_id: watchlistId,
      };
    } else {
      body = {
        domain_names: cleanDomains(domainNames),
      };
    }

    if (newScreenerName) {
      return axios({
        method: "POST",
        url: "/api/v1/screeners",
        data: {
          name: newScreenerName,
          ...body,
        },
        withCredentials: true,
      })
        .then((response) => {
          const redirectScreenerId = camelize<{
            details: {
              screenerId: string,
            }
          }>(response.data).details.screenerId;
          setNextPhase(phase + 1, redirectScreenerId);
        })
        .catch(() => {
          setError("Unable to create screener");
        })
        .finally(() => {
          setState((state) => {
            state.locked = false;
          });
        });
    } else {
      return axios({
        method: "POST",
        url: `/api/v1/screeners/${screenerId}/entries`,
        data: {
          unmatched: [],
          ...body,
        },
        withCredentials: true,
      })
        .then(() => {
          setNextPhase(phase + 1, screenerId);
        })
        .catch(() => {
          setError("Unable to add to screener");
        })
        .finally(() => {
          setState((state) => {
            state.locked = false;
          });
        });
    }
  };

  return (
    <ModalComponents.ModalPage
      title={"Add to Screener"}
      body={
        <>
          <Box
            sx={{
              padding: "1rem 0",
            }}
          >
            <Autocomplete
              size="small"
              options={[
                NewScreenerOption,
                ...[...screeners]
                  .sort(
                    (a, b) =>
                      new Date(b.createdAt).getTime() -
                      new Date(a.createdAt).getTime()
                  )
                  .map((l) => ({
                    id: l.id,
                    label: l.name,
                  })),
              ]}
              getOptionLabel={(option) => option.label}
              isOptionEqualToValue={(option: Option, value: Option) => {
                return option.id === value.id;
              }}
              disableClearable
              value={selectedScreener}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
              onChange={(e, value: Option) => {
                setSelectedScreener(value);
              }}
            />
            {selectedScreener.label === NewScreenerOption.label ? (
              <Box
                sx={{
                  paddingTop: "0.5rem",
                }}
              >
                <TextField
                  size="small"
                  variant="outlined"
                  label="New Screener Name"
                  required
                  fullWidth
                  onChange={(e) => {
                    setNewScreenerName(e.target.value);
                  }}
                />
              </Box>
            ) : null}
          </Box>
          <Box>
            <Typography variant="h6" component="h4">
              Domains
            </Typography>
            <TruncatedList
              domains={domainNames}
              maxRows={8}
              icon={<ArrowRight color="success" />}
              undeterminedSize
            />
          </Box>
        </>
      }
      buttons={
        <Box
          display="flex"
          flexDirection="row"
          alignItems="stretch"
          width="100%"
          gap="1rem"
        >
          <Button
            color="primary"
            variant="contained"
            sx={{
              width: "50%",
            }}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            sx={{
              width: "50%",
            }}
            onClick={() => {
              onSubmit(
                selectedScreener.id,
                newScreenerName,
                domainNames,
                watchlistId,
                searchId,
                searchIterationId,
                phase,
                setNextPhase,
              ).then(() => refresh());
            }}
            disabled={
              state.locked ||
              (selectedScreener.id === NewScreenerOption.id &&
                newScreenerName.length === 0)
            }
            endIcon={
              state.locked ? (
                <CircularProgress color="inherit" size={16} />
              ) : null
            }
          >
            Submit
          </Button>
        </Box>
      }
    />
  );
};

const Success = ({
  domainNames,
  newListName,
  selectedList,
  redirectListId,
  onClose,
  undeterminedSize,
}: {
  domainNames: string[];
  newListName: string;
  selectedList: Option;
  redirectListId: string;
  onClose: () => void;
  undeterminedSize: boolean;
}) => {
  const title =
    newListName.length > 0
      ? `new screener '${newListName}'`
      : `screener '${selectedList.label}'`;
  const content =
    undeterminedSize
      ? `Added all domains to ${title}`
      : `Added ${domainNames.length} domains to ${title}`;
  return (
    <ModalComponents.ModalPage
      title={"Success"}
      body={<ModalComponents.ModalBodyText content={content} />}
      buttons={
        <>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            onClick={onClose}
          >
            OK
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            href={`/screeners/${redirectListId}`}
          >
            Go to Screener
          </Button>
        </>
      }
    />
  );
};

const AddToScreenerModal = ({
  open,
  onClose,
  domainNames,
  watchlistId,
  searchId,
  searchIterationId,
  undeterminedSize,
}: {
  open: boolean;
  onClose: () => void;
  domainNames: string[];
  watchlistId: string;
  searchId: string;
  searchIterationId: string;
  redirect?: boolean;
  refreshData?: () => void;
  undeterminedSize: boolean;
}) => {
  const [state, setState] = useImmer<{
    loaded: boolean;
    selectedScreener: Option;
    newScreenerName: string;
    screeners: Screener[];
    redirectScreenerId: string;
    phase: number;
  }>({
    loaded: false,
    selectedScreener: NewScreenerOption,
    newScreenerName: "",
    screeners: [],
    redirectScreenerId: "",
    phase: 0,
  });

  const initialize = () => {
    axios({
      method: "GET",
      url: "/api/v1/screeners",
      withCredentials: true,
    })
      .then((response) => {
        setState((state) => {
          state.screeners = camelize<{screeners: Screener[]}>(response.data).screeners as Screener[];
          state.loaded = true;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (!state.loaded) {
      initialize();
    }
  });

  const resetAndClose = () => {
    setState((state) => {
      state.phase = 0;
      state.selectedScreener = NewScreenerOption;
      state.newScreenerName = "";
      state.redirectScreenerId = "";
    });
    onClose();
  };

  return (
    <ModalComponents.ModalContainer open={open} onClose={resetAndClose}>
      {state.phase === 0 ? (
        <Overview
          screeners={state.screeners}
          selectedScreener={state.selectedScreener}
          domainNames={domainNames}
          watchlistId={watchlistId}
          searchId={searchId}
          searchIterationId={searchIterationId}
          setSelectedScreener={(value: Option) => {
            setState((state) => {
              state.selectedScreener = value;
            });
          }}
          newScreenerName={state.newScreenerName}
          setNewScreenerName={(name: string) => {
            setState((state) => {
              state.newScreenerName = name;
            });
          }}
          phase={state.phase}
          setNextPhase={(phase: number, redirectScreenerId: string) => {
            setState((state) => {
              state.phase += 1;
              state.redirectScreenerId = redirectScreenerId;
            });
          }}
          onClose={resetAndClose}
          refresh={() => initialize()}
        />
      ) : (
        <Success
          domainNames={domainNames}
          newListName={state.newScreenerName}
          selectedList={state.selectedScreener}
          redirectListId={state.redirectScreenerId}
          onClose={resetAndClose}
          undeterminedSize={undeterminedSize}
        />
      )}
    </ModalComponents.ModalContainer>
  );
};

export type AddToScreenerModalContextValue = {
  show: (
    domains: string[],
    refreshData?: () => void,
    redirect?: boolean
  ) => Promise<void>,
  showFromWatchlist: (
    watchlistId: string,
    refreshData?: () => void,
    redirect?: boolean
  ) => Promise<void>,
  showFromSearchIteration: (
    searchId: string,
    searchIterationId: string,
    refreshData?: () => void,
    redirect?: boolean
  ) => Promise<void>,
};

export const AddToScreenerModalContext =
  createContext<AddToScreenerModalContextValue>({
    show: async (
      _domains: string[],
      _refreshData?: () => void,
      _redirect?: boolean
    ) => undefined,
    showFromWatchlist: async (
      _watchlistId: string,
      _refreshData?: () => void,
      _redirect?: boolean
    ) => undefined,
    showFromSearchIteration: async (
      _searchId: string,
      _searchIterationId: string,
      _refreshData?: () => void,
      _redirect?: boolean
    ) => undefined,
  });

const AddToScreenerModalProvider = ({ children }: { children: any }) => {
  const [state, setState] = useImmer<{
    open: boolean;
    onClose: () => void;
    domains: string[];
    refreshData: () => void;
    redirect: boolean;
    totalEntries?: number;
    searchId: string;
    watchlistId: string;
    searchIterationId: string;
    undeterminedSize: boolean;
      }>({
        open: false,
        onClose: () => undefined,
        domains: [],
        refreshData: () => undefined,
        redirect: false,
        totalEntries: undefined,
        searchId: "",
        watchlistId: "",
        searchIterationId: "string",
        undeterminedSize: false,
      });

  const loadFromWatchlist = (watchlistId: string): Promise<string[]> => {
    return axios({
      method: "GET",
      url: `/api/v1/watchlists/${watchlistId}/entries`,
      withCredentials: true,
    }).then((response) => {
      return (camelize<{entries: { domainName: string }[]}>(response.data)["entries"]).map((e) => e.domainName);
    });
  };

  const loadSearchIteration = (searchId: string, iterationId: string): Promise<string[]> => {
    return axios({
      method: "GET",
      url: `/api/v1/searches/${searchId}/iterations/${iterationId}/results`,
      params: {
        limit: 10,
        offset: 0,
      },
    }).then((response) => {
      return (camelize<{entries: { domainName: string }[]}>(response.data)["entries"]).map((e) => e.domainName);
    });
  };

  const context = {
    show: async (domains, refreshData?: () => void, redirect?: boolean) => {
      setState((state) => {
        state.domains = domains;
        state.refreshData = refreshData || (() => undefined);
        state.redirect = redirect || false;
        state.open = true;
        state.undeterminedSize = false;
      });
    },
    showFromWatchlist: async (watchlistId, refreshData?: () => void, redirect?: boolean) => {
      const domains = await loadFromWatchlist(watchlistId);
      setState((state) => {
        state.domains = domains;
        state.watchlistId = watchlistId;
        state.refreshData = refreshData || (() => undefined);
        state.redirect = redirect || false;
        state.open = true;
        state.undeterminedSize = true;
      });
    },
    showFromSearchIteration: async (searchId: string, searchIterationId: string, refreshData?: () => void, redirect?: boolean) => {
      const domains = await loadSearchIteration(searchId, searchIterationId);
      setState((state) => {
        state.domains = domains;
        state.searchId = searchId;
        state.searchIterationId = searchIterationId;
        state.refreshData = refreshData || (() => undefined);
        state.redirect = redirect || false;
        state.open = true;
        state.undeterminedSize = true;
      });
    },
  } as AddToScreenerModalContextValue;

  return (
    <AddToScreenerModalContext.Provider value={context}>
      {children}
      <AddToScreenerModal
        open={state.open}
        onClose={() => {
          setState((state) => {
            state.open = false;
            state.domains = [];
            state.watchlistId = "";
            state.searchId = "";
            state.searchIterationId = "";
            state.undeterminedSize = false;
          });
        }}
        refreshData={state.refreshData}
        domainNames={state.domains}
        watchlistId={state.watchlistId}
        searchId={state.searchId}
        searchIterationId={state.searchIterationId}
        redirect={state.redirect || false}
        undeterminedSize={state.undeterminedSize}
      />
    </AddToScreenerModalContext.Provider>
  );
};

export default AddToScreenerModalProvider;
