import { AlertColor } from "@mui/material/Alert";
import { AxiosError } from "axios";
import React, { ReactNode, createContext } from "react";
import { useImmer } from "use-immer";

import Toast from "../components/Toast";
import { camelize } from "../utils";

type Message = string | ReactNode;

export type ToastContextValue = {
  setInfo: (text: Message) => void;
  setSuccess: (text: Message) => void;
  setWarning: (text: Message) => void;
  setError: (text: Message) => void;
  setMessage: (text: Message, alertColor: AlertColor) => void;
  setMessageFromResponse: (response: AxiosError, defaultMessage?: string) => void;
  setMessageFromError: (response: AxiosError, defaultMessage?: string) => void;
};

export type UserMessage = {
  message: string;
  severity: AlertColor;
}

export const ToastContext = createContext<ToastContextValue>({
  setInfo: (_text: Message) => undefined,
  setSuccess: (_text: Message) => undefined,
  setWarning: (_text: Message) => undefined,
  setError: (_text: Message) => undefined,
  setMessage: (_text: Message, _alertColor: AlertColor) => undefined,
  setMessageFromResponse: (_response: AxiosError, _defaultMessage?: string) => undefined,
  setMessageFromError: (_response: AxiosError, _defaultMessage?: string) => undefined,
});

const ToastProvider = ({ children }: { children: ReactNode }) => {
  const [state, setState] = useImmer<{
    open: boolean;
    text: Message;
    alertColor: AlertColor;
    onClose: () => void;
      }>({
        open: false,
        text: "",
        alertColor: "" as AlertColor,
        onClose: () => undefined,
      });

  const setMessage = (text: Message, alertColor: AlertColor) => {
    setState((state) => {
      state.text = text;
      state.alertColor = alertColor;
      state.open = true;
    });
  };

  const setMessageFromResponse = (error: AxiosError, defaultMessage = "Success!") => {
    if (error.response) {
      const userMessage = camelize<{userMessage: UserMessage}>(error.response.data as Blob)["userMessage"] as UserMessage;
      setMessage(userMessage.message || defaultMessage, userMessage.severity);
    } else {
      setMessage(defaultMessage, "success");
    }
  };

  const setMessageFromError = async (error: AxiosError, defaultMessage = "Something went wrong!") => {
    try {
      if (error.response) {
        const userMessage = camelize<{userMessage: UserMessage}>(JSON.parse(await (error.response.data as Blob).text()))["userMessage"] as UserMessage;
        setMessage(userMessage.message || defaultMessage, userMessage.severity.toLowerCase() as AlertColor);
      } else {
        setMessage(defaultMessage, "error");
      }
    } catch {
      setMessage(defaultMessage, "error");
    }
  };

  const context = {
    setInfo: (text: Message) => {
      setMessage(text, "info" as AlertColor);
    },
    setWarning: (text: Message) => {
      setMessage(text, "warning" as AlertColor);
    },
    setSuccess: (text: Message) => {
      setMessage(text, "success" as AlertColor);
    },
    setError: (text: Message) => {
      setMessage(text, "error" as AlertColor);
    },
    setMessage: setMessage,
    setMessageFromResponse: setMessageFromResponse,
    setMessageFromError: setMessageFromError,
  } as ToastContextValue;

  return (
    <ToastContext.Provider value={context}>
      {children}
      <Toast
        message={state.text}
        onClose={() => {
          setState((state) => {
            state.open = false;
          });
        }}
        open={state.open}
        severity={state.alertColor}
        style={{
          paddingBottom: "4rem",
        }}
      />
    </ToastContext.Provider>
  );
};

export default ToastProvider;
