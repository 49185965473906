import * as React from "react";
import { ReactNode, useContext, useEffect } from "react";

import { PageHeaderContext } from "../../providers/PageHeaderProvider";

const PageHeader = ({
  title,
  subtitle,
  onBack,
  children,
  help,
  sticky = false,
  forceUpdate,
}: {
  title: string;
  subtitle?: string;
  onBack?: () => void;
  children?: ReactNode;
  help?: ReactNode;
  sticky?: boolean;
  forceUpdate?: any;
}) => {
  const pageHeader = useContext(PageHeaderContext);

  useEffect(() => {
    if (pageHeader.loaded) {
      pageHeader.setPageHeader(title, subtitle, onBack, help, children, sticky);
    }
  }, [pageHeader.loaded]);

  useEffect(() => {
    pageHeader.setPageHeader(title, subtitle, onBack, help, children, sticky);
  }, [title, subtitle, onBack, help]);

  useEffect(() => {
    pageHeader.setPageHeader(title, subtitle, onBack, help, children, sticky);
  }, [forceUpdate]);

  return <></>;
};

export default PageHeader;
