import { Box, Button, CircularProgress, TextField } from "@mui/material";
import axios from "axios";
import * as React from "react";
import { ReactNode, createContext, useContext, useEffect } from "react";
import { useImmer } from "use-immer";

import { ToastContext } from "../../providers/ToastProvider";
import { camelize } from "../../utils";
import ModalComponents from "../Modal";

type SuggestKeywordModalContextValue = {
  openModal: (keywordGroupId: string) => void;
  setCallback: (fn: (keywords: string[], keywordGroupId: string) => void) => void;
  loaded: boolean;
};

export const SuggestKeywordModalContext =
  createContext<SuggestKeywordModalContextValue>({
    openModal: (_keywordGroupId: string) => undefined,
    setCallback: (_fn: (keywords: string[], keywordGroupId: string) => void) => undefined,
    loaded: false,
  });

const KeywordSuggestionModal = ({
  open,
  onClose,
  setKeywords,
  onSubmit,
  inProgress,
  activeKeywordGroupId,
}: {
  open: boolean;
  onClose: () => void;
  setKeywords: (keywords: string) => void;
  onSubmit: () => void;
  inProgress: boolean;
  activeKeywordGroupId: string | null;
}) => {
  const MODAL_CONTENT = {
    exclude: {
      info: "Describe company types you'd like to exclude from the search",
      placeholder: "ex: non-profit, universities"
    },
    secondary_must: {
      info: "Describe the specialization of companies you're looking for", 
      placeholder: "ex: payment processing software, talent placement services company, residential hvac company"
    },
    default: {
      info: "Describe what types of companies you're looking for",
      placeholder: "ex: software, consulting, HR services, marketing agency, etc"
    }
  };

  const getInfo = () => {
    return MODAL_CONTENT[activeKeywordGroupId as keyof typeof MODAL_CONTENT]?.info || MODAL_CONTENT.default.info;
  };

  const getPlaceholder = () => {
    return MODAL_CONTENT[activeKeywordGroupId as keyof typeof MODAL_CONTENT]?.placeholder || MODAL_CONTENT.default.placeholder;
  };
  return (
    <ModalComponents.ModalContainer open={open} onClose={onClose}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
          // onClose()
        }}
      >
        <ModalComponents.ModalPage
          title={"Suggest Keywords"}
          body={
            <>
              <h3>{getInfo()}</h3>
              <TextField
                placeholder={getPlaceholder()}
                minRows={3}
                multiline
                fullWidth
                onChange={(e) => {
                  setKeywords(e.target.value);
                }}
              />
            </>
          }
          buttons={
            <Box
              display="flex"
              flexDirection="row"
              alignItems="stretch"
              width="100%"
              gap="1rem"
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={onClose}
                sx={{
                  width: "50%",
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                sx={{
                  width: "50%",
                }}
                disabled={inProgress}
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  gap="0.5rem"
                >
                  Suggest Keywords
                  {inProgress ? (
                    <CircularProgress color="inherit" size={16} />
                  ) : null}
                </Box>
              </Button>
            </Box>
          }
        />
      </form>
    </ModalComponents.ModalContainer>
  );
};

const KeywordSuggestionModalProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { setError } = useContext(ToastContext);

  const [state, setState] = useImmer<{
    loaded: boolean;
    open: boolean;
    keywords: string;
    callbackFn: (keywords: string[], keywordGroupId: string) => void;
    inProgress: boolean;
    activeKeywordGroupId: string | null;
      }>({
        loaded: false,
        open: false,
        keywords: "",
        callbackFn: (_keywords: string[], _keywordGroupId: string) => undefined,
        inProgress: false,
        activeKeywordGroupId: null,
      });

  const initialize = () => {
    setState((state) => {
      state.loaded = true;
    });
  };

  useEffect(() => {
    if (!state.loaded) {
      initialize();
    }
  }, []);

  const onSubmit = () => {
    setState((state) => {
      state.inProgress = true;
    });
    return axios({
      method: "POST",
      url: "/api/v1/searches/iterations/keywords/suggest",
      data: {
        keywords: state.keywords,
      },
      withCredentials: true,
    })
      .then((response) => {
        setState((state) => {
          const body = camelize<{keywords: string[]}>(response.data);
          const keywords = body["keywords"] as string[];
          state.callbackFn(keywords, state.activeKeywordGroupId ?? "");
        });
        onClose();
      })
      .catch(function (error) {
        console.log(error);
        setError("Unable to generate keywords, please try again");
      })
      .finally(() => {
        setState((state) => {
          state.inProgress = false;
        });
      });
  };

  const onClose = () => {
    setState((state) => {
      state.open = false;
      state.keywords = "";
      state.inProgress = false;
    });
  };

  const context = {
    openModal: (keywordGroupId: string) => {
      setState((state) => {
        state.open = true;
        state.activeKeywordGroupId = keywordGroupId;
      });
    },
    setCallback: (
      callbackFn: (keywords: string[], keywordGroupId: string) => void
    ) => {
      setState((state) => {
        state.callbackFn = callbackFn;
      });
    },
    loaded: state.loaded,
  };

  return (
    <SuggestKeywordModalContext.Provider value={context}>
      {children}
      <KeywordSuggestionModal
        open={state.open}
        onClose={onClose}
        activeKeywordGroupId={state.activeKeywordGroupId}
        setKeywords={(keywords: string) => {
          setState((state) => {
            state.keywords = keywords;
          });
        }}
    
        onSubmit={onSubmit}
        inProgress={state.inProgress}
      />
    </SuggestKeywordModalContext.Provider>
  );
};

export default KeywordSuggestionModalProvider;
