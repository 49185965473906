import { ThemeOptions, createTheme } from "@mui/material";

export const themeOptions: ThemeOptions = createTheme({
  palette: {
    primary: {
      main: "#34383B",
    },
    secondary: {
      main: "#22AAE1",
      contrastText: "#FFFFFF",
    },
    success: {
      main: "#8DC640",
    },
    background: {
      default: "#FFFFFF",
    },
    info: {
      main: "#FFFFFF",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 700,
        },
        contained: {
          "&.Mui-disabled": {
            boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)" // default elevation 1
          }
        }
      },
    },
  },
});
